import { isAnywhereUrl } from '@wix/wix-anywhere-api';
import { ControllerFlowAPI, IWixAPI } from '@wix/yoshi-flow-editor';
import { toError } from './errors';

export async function isDayful(flowAPI: ControllerFlowAPI, wixCodeApi: IWixAPI) {
  try {
    const { baseUrl, url } = wixCodeApi.location;
    if (baseUrl && url) {
      return await isAnywhereUrl({ externalBaseUrl: baseUrl, fullUrl: url });
    }
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e));
  }
  return false;
}
